import type { IProduct } from '@/services/models/Product';
import { useMemo } from 'react';
import ProductTileVM from '../view-models/ProductTileVM';

/**
 * Creates a product tile view model from a given {@link IProduct}.
 * @param product - The product to create a view model from.
 * @returns A product view model.
 */
export const useProductTile = (product: IProduct): ProductTileVM => {
  const productTileVM = useMemo(() => {
    return new ProductTileVM(product);
  }, [product]);

  return productTileVM;
};
