import { HoverIntent } from '@/react/components/utility/HoverIntent';
import { classes } from '@/next-utils/css-utils/scss-utils';
import type { IColorVariationAttribute } from '@/services/models/Product/variation-attributes';
import type { CSSProperties, FC, MouseEventHandler } from 'react';
import type { VoidCallback } from '@/type-utils';
import LoggerService from '@/services/isomorphic/LoggerService';
import type { HexColor } from './HexColor';

import S from './styles.module.scss';

/**
 * Represents a swatch variation for the product tile.
 *
 * It extends the IColorVariationAttribute interface to support multiple colors.
 */
export interface ISwatchVariation
  extends Omit<IColorVariationAttribute, 'hexValue'> {
  /**
   * The SKU of the product variation. This is used to identify the product variation.
   */
  sku: string;

  /**
   * Hex value for setting the look of a color swatch. Combinations are represented in an array of strings.
   *
   * Overrides the hexValue property from the IColorVariationAttribute interface to support multiple colors.
   * */
  hexValue: string | Array<string>;
}

interface ISwatchProps extends Omit<ISwatchVariation, 'sku'> {
  /** If the swatch is currently selected. */
  active?: boolean;
  /**
   * Callback to run on swatch click.
   *
   * @param event
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Callback to run on hover. */
  onHover?: VoidCallback;

  /** Mouse leave action. */
  onLeave?: VoidCallback;
}

/** Represents a valid hex value that can be used in a swatch. */
type SwatchHexValue = HexColor | [HexColor, HexColor];

/**
 *  Get the background style object for the swatch from the swatch hex value.
 *
 *  @param hexValue - The hex value of the swatch.
 *  @returns The background style object, that can be a linear gradient or a single color.
 */
const getSwatchStyleObject = (hexValue: SwatchHexValue): CSSProperties => {
  if (Array.isArray(hexValue)) {
    if (hexValue.length <= 1) {
      LoggerService.warn('Swatch hex array has less than 2 colors');

      // Default fallback color to white to ensure the swatch is built.
      const backgroundColor = hexValue?.[0] || '#fff';

      return {
        backgroundColor,
        border: backgroundColor === '#fff' ? '1px solid #000' : undefined
      };
    }

    // If there is more than one color, create a linear gradient splitting the colors in 45 degrees.
    return {
      backgroundImage: `linear-gradient(-45deg, ${hexValue[0]} 0%, ${hexValue[0]} 50%, ${hexValue[1]} 50%, ${hexValue[1]} 50%)`
    };
  }

  // If the color is represented as a string, return as background color.
  const backgroundColor = hexValue?.[0] || '#fff';

  return {
    backgroundColor,
    border: backgroundColor === '#fff' ? '1px solid #000' : undefined
  };
};

/**
 * A swatch for variations in the the product tile.
 */
export const Swatch: FC<ISwatchProps> = ({
  active,
  hexValue,
  onHover,
  onClick,
  onLeave
}) => {
  return (
    <HoverIntent onHoverIn={onHover} onHoverOut={onLeave} delay={0}>
      <button className={S.button} type="button" onClick={onClick}>
        <div
          className={classes(S.swatch, active && S.active)}
          style={getSwatchStyleObject(hexValue as SwatchHexValue)}
        />
      </button>
    </HoverIntent>
  );
};
