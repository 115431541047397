import { RecommendationContext } from '@/configs/recommendations';
import { InvalidArgumentError } from '@/utils/errors';
import { exhaustiveFallback } from '@/utils/function-utils';
import ConfigurationService from '../ConfigurationService';
import type { IRecommendationContextConfig } from './IRecommendationContextConfig';
import LoggerService from '../LoggerService';

/**
 * Gets the recommendation config values for a given context.
 * @param context - The recommendation context.
 * @returns - An object holding values useful to the frontend.
 * @throws If an invalid context is supplied.
 */
export function getContextConfig(
  context: RecommendationContext
): IRecommendationContextConfig {
  let isRecommendationEnabled = false;
  let isSlideOnMobile = false;

  const config = ConfigurationService.getConfig('recommendations');

  switch (context) {
    case RecommendationContext.Account: {
      isRecommendationEnabled = config.getSetting('account.enabled').value;
      isSlideOnMobile = config.getSetting('account.isSlideOnMobile').value;
      break;
    }

    case RecommendationContext.Cart: {
      isRecommendationEnabled = config.getSetting('cart.enabled').value;
      isSlideOnMobile = config.getSetting('cart.isSlideOnMobile').value;
      break;
    }

    case RecommendationContext.HomePage: {
      isRecommendationEnabled = config.getSetting('homepage.enabled').value;
      isSlideOnMobile = config.getSetting('homepage.isSlideOnMobile').value;
      break;
    }

    case RecommendationContext.NotFoundPage: {
      isRecommendationEnabled = config.getSetting('notFoundPage.enabled').value;
      isSlideOnMobile = config.getSetting('notFoundPage.isSlideOnMobile').value;
      break;
    }

    case RecommendationContext.PDP: {
      isRecommendationEnabled = config.getSetting('pdp.enabled').value;
      isSlideOnMobile = config.getSetting('pdp.isSlideOnMobile').value;
      break;
    }

    case RecommendationContext.PairsWith: {
      isRecommendationEnabled = config.getSetting('pairsWith.enabled').value;
      isSlideOnMobile = config.getSetting('pairsWith.isSlideOnMobile').value;
      break;
    }

    case RecommendationContext.AddToCart: {
      isRecommendationEnabled = config.getSetting('addToCart.enabled').value;
      isSlideOnMobile = config.getSetting('addToCart.isSlideOnMobile').value;
      break;
    }

    case RecommendationContext.Quickview: {
      isRecommendationEnabled = config.getSetting('addToCart.enabled').value;
      isSlideOnMobile = config.getSetting('addToCart.isSlideOnMobile').value;
      break;
    }

    case RecommendationContext.Storybook: {
      isRecommendationEnabled = config.getSetting('storybook.enabled').value;
      isSlideOnMobile = config.getSetting('storybook.isSlideOnMobile').value;
      break;
    }

    default: {
      exhaustiveFallback(context, () => {
        LoggerService.error(
          new InvalidArgumentError(
            `The context: ${context}, is not currently being handled.`
          )
        );
      });
    }
  }

  return { isRecommendationEnabled, isSlideOnMobile };
}
