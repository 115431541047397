import { FunctionComponent } from 'react';
import { Icon } from '../../../../core-ui/Icon';

import { ISliderNavigationButtonIconProps } from './ISliderNavigationButtonIconProps';

import { ArrowVariants } from '../helpers';

import S from './styles.base.module.scss';

/**
 * The Slider navigation arrow component (Basically just a fontawesome wrapper).
 * This is the default component for rendering arrows, but another component
 * can be specified (see `ISliderNavigationOptions`).
 *
 * @example ```tsx
 * <SliderNavigationButtonIcon direction="up" variant="caret" />
 * <SliderNavigationButtonIcon direction="right" variant="angle" />
 * ```
 */
export const SliderNavigationButtonIcon: FunctionComponent<
  ISliderNavigationButtonIconProps
> = ({ direction, variant, size }) => (
  <Icon
    className={S.icon}
    icon={ArrowVariants[variant][direction]}
    size={size}
  />
);
