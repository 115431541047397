'use client';

import { Icon, IconTypes } from '@/react/components/core-ui/Icon';
import { Link } from '@/react/components/core-ui/Link';
import { useRouter } from '@/react/utils/router-utils/useRouter';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ProductAvailabilityState } from '@/services/models/Inventory';
import { ReturnsFlowStateModel } from '@/services/models/Returns/flow';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { useErrorAlert } from '@/react/hooks/useErrorAlert';
import type { IProductVM } from '@/react/view-models/ProductVM';
import { DisplayVariationAttributeType } from '@/react/view-models/ProductVM';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import {
  EventType,
  InteractionDetails
} from '@/services/isomorphic/UserInteractionService';
import {
  CartTotalQuantityExceededError,
  LineItemQuantityExceededError
} from '@/services/models/Cart';
import type { IProduct } from '@/services/models/Product';
import { ReturnReason } from '@/services/models/Returns/order';
import { ReturnItemType } from '@/services/models/Returns/order/item';
import { InvalidStateError } from '@/utils/errors';
import { exhaustiveGuard } from '@/utils/function-utils';
import { isNullOrEmpty } from '@/utils/null-utils';
import Image from 'next/image';
import { useGlobalContext } from '../../../hooks/useGlobalContext';
import { withEcommerceEnabled } from '../../utility/EcommerceEnabled';
import { AddToCartModal } from '../AddToCartModal';
import ProductDetailsVariant from '../../product/ProductDetailsVariant';
import NotificationType from '../../utility/notifications/NotificationType';
import type { ICustomNotification } from '../../utility/notifications/types';
import {
  Breakpoint,
  Breakpoints,
  mediaMatches
} from '../../core-ui/Breakpoints';
import { Button, type ButtonVariant } from '../../core-ui/Button';
import NotificationContext from '../../utility/notifications/controller/NotificationContext';

import S from './styles.base.module.scss';
import { product_details_viewCart } from "@/lang/__generated__/ahnu/product_details_viewCart";
import { product_details_checkoutNow } from "@/lang/__generated__/ahnu/product_details_checkoutNow";
import { cart_errors_lineQuantityExceeded } from "@/lang/__generated__/ahnu/cart_errors_lineQuantityExceeded";
import { cart_errors_cartTotalQuantityExceeded } from "@/lang/__generated__/ahnu/cart_errors_cartTotalQuantityExceeded";
import { product_details_addToCart_addColor } from "@/lang/__generated__/ahnu/product_details_addToCart_addColor";
import { product_details_addToCart_disabledText } from "@/lang/__generated__/ahnu/product_details_addToCart_disabledText";
import { product_details_unavailable } from "@/lang/__generated__/ahnu/product_details_unavailable";
import { product_details_update } from "@/lang/__generated__/ahnu/product_details_update";
import { product_details_addToExchange } from "@/lang/__generated__/ahnu/product_details_addToExchange";
import { general_addToCart } from "@/lang/__generated__/ahnu/general_addToCart";
import { product_details_addedToCart } from "@/lang/__generated__/ahnu/product_details_addedToCart";

interface IAddToCartButtonProps {
  /**
   * The variant of this details panel.
   * @see {@link ProductDetailsVariant} for more info on each variant.
   */
  variant?: ProductDetailsVariant;

  /** The product view model. */
  product: IProductVM;

  /**
   * The previously selected product that will be replaced on resolve.
   * For use with `variant = ProductDetailsVariant.Update`.
   */
  productToUpdate?: IProduct;

  /** How the buttons should be rendered. */
  buttonVariant?: ButtonVariant;

  /**
   * Callback that will be executed when a product is added or updated.
   * @param newProduct - The new product variant that was added or updated.
   */
  onAddToCart?: (newProduct: IProduct) => void;
}

interface IAddToCartButtonUpdateProps extends IAddToCartButtonProps {
  /** @inheritdoc */
  variant: ProductDetailsVariant.Update;

  /** @inheritdoc */
  productToUpdate: IProduct;
}

/**
 * The props for the AddToCartButton component.
 */
type AddToCartButtonProps<T extends IAddToCartButtonProps> =
  T['variant'] extends ProductDetailsVariant.Update
    ? IAddToCartButtonUpdateProps & T
    : T['productToUpdate'] extends IProduct
      ? IAddToCartButtonUpdateProps & T
      : T;

/** Add to cart component for use on shoppable tiles and the pdp. */
export const AddToCartButton = observer(function AddToCartButton<
  T extends IAddToCartButtonProps
>({
  variant = ProductDetailsVariant.Default,
  product,
  productToUpdate,
  buttonVariant = 'primary',
  onAddToCart
}: AddToCartButtonProps<T>) {
  const [msg, msgf] = useLocaleMessage();
  const router = useRouter();
  const { queueNotification, deleteNotification } =
    useContext(NotificationContext);
  const [isHandlingAction, setIsHandlingAction] = useState(false);
  const [quantityErrorText, setQuantityErrorText] = useState<string | null>(
    null
  );

  const { cart } = useGlobalContext();
  const { alert } = useErrorAlert();

  useEffect(
    /**
     * Clears the error text when the cart quantity changes.
     *
     * This is necessary to allow the user to try adding the product to the cart again
     * after the quantity has changed, such as when the user updates the cart from `MiniCart`.
     */
    function clearErrorTextOnCartQuantityChange() {
      setQuantityErrorText(null);
    },
    [cart.totalItemQuantity]
  );

  // Sets add to cart modal to open.
  const [open, setOpen] = useState(false);

  const handleAction = async (): Promise<void> => {
    setIsHandlingAction(true);
    try {
      switch (variant) {
        case ProductDetailsVariant.Quickview:
        case ProductDetailsVariant.Tile:
        case ProductDetailsVariant.Default: {
          try {
            await product.addToCart(cart);

            // Display success messaging, which will be...
            if (!mediaMatches(window, 'desktop')) {
              const image = product.images[0];
              // A notification on mobile.
              const notificationID = queueNotification({
                type: NotificationType.Custom,
                content: (
                  <div className={S.addToCartNotification}>
                    <div className={S.notificationContent}>
                      <Image
                        alt={image.alt ?? ''}
                        src={image.src}
                        width={50}
                        height={50}
                      />
                      <div className={S.notificationText}>
                        {msgf(product_details_addedToCart, {
                          productName: product.name
                        })}
                      </div>
                    </div>
                    <div className={S.notificationLinks}>
                      <Link
                        href="/cart"
                        className={S.notificationLink}
                        // close upon navigating away
                        onClick={() => deleteNotification(notificationID)}
                      >
                        <Button
                          variant="secondary"
                          className={S.notificationButton}
                        >
                          {msg(product_details_viewCart)}
                        </Button>
                      </Link>
                      <Link
                        href="/checkout"
                        className={S.notificationLink}
                        // close upon navigating away
                        onClick={() => deleteNotification(notificationID)}
                      >
                        <Button className={S.notificationButton}>
                          <Icon icon={IconTypes.Lock} />
                          {msg(product_details_checkoutNow)}
                        </Button>
                      </Link>
                    </div>
                  </div>
                )
              } as ICustomNotification);
            } else {
              // Opening the modal on desktop.
              setOpen(true);
            }
          } catch (err) {
            if (err instanceof LineItemQuantityExceededError) {
              setQuantityErrorText(msg(cart_errors_lineQuantityExceeded));
            } else if (err instanceof CartTotalQuantityExceededError) {
              setQuantityErrorText(
                msg(cart_errors_cartTotalQuantityExceeded)
              );
            } else {
              throw err;
            }
          }
          break;
        }

        case ProductDetailsVariant.Update: {
          // Update the old product instead of adding a new one.
          if (!productToUpdate) {
            throw new InvalidStateError(
              'Product to update missing. Please include it as the `productToUpdate` prop.'
            );
          }

          const productLineItemToUpdate = cart.items.find((item) => {
            return item.sku === productToUpdate.sku;
          });

          if (productLineItemToUpdate) {
            await product.replaceInCart(cart, productLineItemToUpdate);
            // hypothetically we don't need to catch cart quantity related errors
            // because replacing a product doesn't change the quantity
          }
          break;
        }

        case ProductDetailsVariant.Exchange: {
          const flowState = ReturnsFlowStateModel.fromSessionStorage();

          await flowState.selectItemReturnType(
            ReturnItemType.DifferentModelExchange,
            ReturnReason.ChangedMind,
            product.sku
          );

          router.push(flowState.getCurrentStepURL());
          break;
        }

        case ProductDetailsVariant.ExchangeQuickview: {
          // Do nothing and let the onResolve callback handle everything.
          break;
        }

        default: {
          exhaustiveGuard(
            variant,
            `Invalid variant passed to AddToCartButton: ${variant}`
          );
        }
      }

      // Runs callback for the resolution of the panel when the modal opens.
      if (onAddToCart) onAddToCart(product.currentProduct);
    } catch (error) {
      alert(error);
    } finally {
      setIsHandlingAction(false);
    }
  };

  const { status, text: availabilityText } = product.availability;

  const buttonContent = useMemo(() => {
    // Determine which text the button will display
    const hasSize = product.hasVariationAttributeSelected(
      DisplayVariationAttributeType.Size
    );

    const hasColor = product.hasVariationAttributeSelected(
      DisplayVariationAttributeType.Color
    );

    const isUnavailable = status === ProductAvailabilityState.Unavailable;

    const isUpdateVariant = variant === ProductDetailsVariant.Update;

    const isExchangeVariant =
      variant === ProductDetailsVariant.Exchange ||
      variant === ProductDetailsVariant.ExchangeQuickview;

    // "Add color" if the product does not have a color selected.
    if (!hasColor) return msg(product_details_addToCart_addColor);

    // "Add size" if the product does not have a size selected.
    if (!hasSize) return msg(product_details_addToCart_disabledText);

    // "Unavailable" if inventory data is unavailable for this product.
    if (isUnavailable) return msg(product_details_unavailable);

    // "Update" messaging if the variant is Update.
    if (isUpdateVariant) return msg(product_details_update);

    // "Add to Exchange" messaging if the variant is one of the Exchange variants.
    if (isExchangeVariant) return msg(product_details_addToExchange);

    // "Add to cart" if nothing else was returned.
    return msg(general_addToCart);
  }, [product, status, variant]);

  const addToCartEvent: InteractionDetails =
    variant === ProductDetailsVariant.Update
      ? { action: EventType.ProductUpdate }
      : { action: EventType.ProductAdd, product: product.currentProduct };

  const isButtonDisabled =
    !product.isPurchasable ||
    isHandlingAction ||
    !isNullOrEmpty(quantityErrorText);

  // Whether or not to show the product's availability status message.
  // Also, is designed to only show when product is NOT in stock, see usage below.
  const cartConfig = ConfigurationService.getConfig('cart');
  const showAvailabilityMessage = cartConfig.getSetting(
    'addToCartButtonAvailabilityMsg'
  ).value;

  return (
    <>
      {!isNullOrEmpty(quantityErrorText) && (
        <span className={S.quantityErrorText}>{quantityErrorText}</span>
      )}

      {variant !== ProductDetailsVariant.Tile && showAvailabilityMessage && (
        <div
          className={classes(S.statusMessage, {
            [S.warning]: !product.isPurchasable,
            // UX requirement: If the product is in stock, do not show the availability text.
            [S.visible]: status !== ProductAvailabilityState.InStock
          })}
        >
          {availabilityText}
        </div>
      )}

      <Button
        fullWidth
        disabled={isButtonDisabled}
        interactionDetails={addToCartEvent}
        className={classes(S.addToCartButton, {
          // Notice that exchange styling is only applied when the variant is Exchange,
          // but not when the variant is ExchangeQuickview. This is the requested behavior
          // in the designs.
          [S.exchange]: variant === ProductDetailsVariant.Exchange
        })}
        onClick={() => {
          handleAction();
        }}
        variant={buttonVariant}
        datatestID="pdpAddToCart"
      >
        {buttonContent}
      </Button>

      <Breakpoints>
        <Breakpoint default />
        <Breakpoint media="desktop">
          {product.isPurchasable && cart.isReady && (
            <AddToCartModal
              product={product.currentProduct}
              opened={open}
              setOpened={setOpen}
            />
          )}
        </Breakpoint>
      </Breakpoints>
    </>
  );
});

export default withEcommerceEnabled(AddToCartButton);
