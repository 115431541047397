'use client';

// Import libraries
import { observer } from 'mobx-react-lite';
import Image, { StaticImageData } from 'next/image';
import { useState } from 'react';
import { EnvironmentService } from '@/services/isomorphic/EnvironmentService';
import { msgf } from '@/services/isomorphic/I18NService';
import type { IProduct } from '@/services/models/Product';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { useProductTile } from '@/react/hooks/useProductTile';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import { PriceModel } from '@/services/models/Price';
import type { DTO, Nullable } from '@/type-utils';
import { isNullish } from '@/utils/null-utils';
import type ProductTileVM from '@/react/view-models/ProductTileVM';
import { Image as GenericImage } from '../../core-ui/Image';
import { Link } from '../../core-ui/Link';
import RatingStars from '../../core-ui/RatingStars';
import { Price } from '../../product/Price';
import type ITestIdentifiable from '../../traits/ITestIdentifiable';
import { HoverIntent } from '../../utility/HoverIntent';
import ImageSwitcher from '../../utility/ImageSwitcher';
import SkeletonLoader from '../../utility/SkeletonLoader';
import QuickviewButton from '../quickview/QuickviewButton';
import ColorSelector, { ColorOption } from './ColorSelector';
import { ProductSwatchSlider } from './ProductSwatchSlider';
import type { ISwatchVariation } from './ProductSwatchSlider/Swatch';

// Import styles and assets
import newStyleBanner from './static/badges/new_style.png';

import S from './styles.base.module.scss';
import { SkipRenderOnServer } from '../../utils/SkipRenderOnServer';
import { product_tiles_numberOfColors } from "@/lang/__generated__/ahnu/product_tiles_numberOfColors";

/** Defines the possible badge types a product tile can have. */
export enum ProductBadge {
  newStyle = 'newStyle'
}

/**
 * Utility to match a `ProductBadge` enum to its corresponding image.
 * @param badge - The badge to use.
 * @returns The badge image or `null` if the supplied badge type is invalid.
 */
const getBadgeImage = (badge: ProductBadge): StaticImageData | null => {
  switch (badge) {
    case ProductBadge.newStyle:
      return newStyleBanner;

    default:
      return null;
  }
};

/** Defines props for the props `ProductTile` component. */
export interface IProductTileProps extends ITestIdentifiable {
  /** The product to display. */
  product: IProduct | DTO<IProduct>;

  /** A badge to include on this tile. */
  badge?: ProductBadge;

  /** Promotional text to include on this tile. */
  promotionText?: string;

  /**
   * Defines tile type.
   * @default "default"
   */
  variant?: 'default' | 'recommendation' | 'cart';

  /**
   * Is recommendation tile in a slide.
   * @default false
   */
  isSlideTile?: boolean;

  /** Show rating stars on this tile. */
  showRatingStars?: boolean;
}

/** Component that displays a product in a results or category page.  */
const ProductTile = observer(function ProductTile({
  product,
  badge,
  promotionText,
  variant = 'default',
  isSlideTile,
  datatestID,
  showRatingStars = true
}: IProductTileProps) {
  const isRecommendationVariant = variant === 'recommendation';
  const isCartVariant = variant === 'cart';

  const pdpConfig = ConfigurationService.getConfig('product');
  const shouldUseQuickColorSelector = pdpConfig.getSetting(
    'plp.useQuickColorSelector'
  ).value;
  const isQuickViewEnabled = pdpConfig.getSetting('quickView.show').value;
  // Check if the color variants counter should be shown.
  const showColorVariantsCounter = pdpConfig.getSetting(
    'colorVariantsCounter.show'
  ).value;

  const productTileViewModel = useProductTile(product);
  const { productModel, colorVariants } = productTileViewModel;
  const { name, primaryImage, secondaryImage, price } = productModel;

  const [currentImage, setCurrentImage] = useState(primaryImage);

  const { origin, pathname } = EnvironmentService.url;

  const priceModel = price ? PriceModel.from(price) : null;

  // Create swatches for each color variant.
  const swatches =
    colorVariants?.map((color) => {
      const [sku] = productModel.selectAttributeSKU(color);
      return { sku, ...color };
    }) ?? [];

  // Retrieve paths to send to product page from a category page.
  const href = productModel.getUrlWithCategory(pathname);

  const shouldDisplayColorSelector =
    !isNullish(colorVariants) && colorVariants.length > 1 && !isCartVariant;

  const onSwatchChange = async ([swatch, previousSwatch]: [
    ISwatchVariation,
    Nullable<ISwatchVariation>
  ]): Promise<void> => {
    if (swatch.sku === previousSwatch?.sku) return;

    if (swatch.secondaryImage) {
      setCurrentImage(swatch.secondaryImage);
    }

    await productTileViewModel?.selectProductVariantBySKU(swatch.sku);
  };

  return (
    <HoverIntent
      delay={0}
      onHoverIn={() => {
        if (!isCartVariant) setCurrentImage(secondaryImage);
      }}
      onHoverOut={() => {
        if (!isCartVariant) setCurrentImage(primaryImage);
      }}
    >
      <div
        className={classes(
          !isCartVariant && S.productTile,
          isRecommendationVariant && S.recommendation,
          isSlideTile && S.slideTile,
          isCartVariant && S.cart,
          !shouldUseQuickColorSelector && S.withProductSwatchSlider
        )}
        data-testid={datatestID}
      >
        <div
          className={classes(
            S.content,
            !shouldUseQuickColorSelector && S.withProductSwatchSlider
          )}
        >
          {badge != null ? (
            <div className={S.badge}>
              <Image
                // Temporary badge id as alt tag.
                alt={badge}
                className={S.image}
                src={getBadgeImage(badge) as StaticImageData}
                fill
              />
            </div>
          ) : null}

          {isQuickViewEnabled && !isCartVariant && (
            <div className={S.quickShopButton}>
              <QuickviewButton product={productModel} />
            </div>
          )}

          {!isCartVariant ? (
            <Link
              href={href}
              variant="text"
              interactionDetails={{
                action: EventType.TileClick,
                product: productModel
              }}
            >
              <div className={S.imageContainer}>
                <ImageSwitcher
                  image={currentImage}
                  imageProps={{ fit: 'contain' }}
                  imageClassName={
                    isRecommendationVariant ? S.slideTileImage : undefined
                  }
                />
              </div>
            </Link>
          ) : (
            <Link
              href={href}
              variant="text"
              interactionDetails={{
                action: EventType.TileClick,
                product: productModel
              }}
            >
              <div className={S.imageContainer}>
                <GenericImage image={primaryImage} />
              </div>
            </Link>
          )}

          <div className={S.productTileDetails}>
            {shouldDisplayColorSelector && !shouldUseQuickColorSelector && (
              <div className={S.productSwatchRow}>
                <ProductSwatchSlider
                  swatches={swatches}
                  swatchesPerSlide={4}
                  onChange={onSwatchChange}
                />
              </div>
            )}

            <Link
              href={href}
              variant="text"
              interactionDetails={{
                action: EventType.TileClick,
                product: productModel
              }}
              datatestID="plpProductName"
            >
              <span className={S.productName}>{name}</span>
            </Link>

            <div className={S.infoRow}>
              {priceModel && (
                <Price price={priceModel} className={S.currentPrice} />
              )}

              {/* Colors/variants */}
              {colorVariants.length > 0 && (
                <div className={S.colorVariantsCountWrapper}>
                  <div className={S.colorVariantsCountDivider}>/</div>
                  <div className={S.colorVariantsCount}>
                    {msgf(product_tiles_numberOfColors, {
                      itemCount: colorVariants.length
                    })}
                  </div>
                </div>
              )}
            </div>

            {shouldDisplayColorSelector && shouldUseQuickColorSelector ? (
              <div className={S.colors}>
                <ColorSelector>
                  {/* TODO: Use actual variant colors here. */}
                  {colorVariants.map((color) => {
                    const [newSKU] = productModel.selectAttributeSKU(color);
                    const url = new URL(`/p/${newSKU}`, origin);

                    return (
                      <ColorOption
                        key={newSKU}
                        color={color}
                        onHover={() => {
                          if (color.secondaryImage) {
                            setCurrentImage(color.secondaryImage);
                          }
                        }}
                        href={url}
                      />
                    );
                  })}
                </ColorSelector>
              </div>
            ) : null}

            {!isNullish(promotionText) && (
              <div className={S.promotionText}>{promotionText}</div>
            )}

            {showRatingStars && !isCartVariant && (
              <ProductTileRatingStars ratings={productTileViewModel.ratings} />
            )}
          </div>
        </div>
      </div>
    </HoverIntent>
  );
});

const ProductTileRatingStars = observer(function ProductTileRatingStars({
  ratings
}: {
  ratings: ProductTileVM['ratings'];
}) {
  return (
    <div className={S.ratings}>
      {/* Skip rendering until after hydration to avoid hydration errors. */}
      <SkipRenderOnServer
        fallback={<SkeletonLoader className={S.skeletonLoader} />}
      >
        {!ratings.pending ? (
          <RatingStars rating={ratings.value?.averageRating ?? 0} />
        ) : (
          // A skeleton loading indicator would go well here.
          <SkeletonLoader className={S.skeletonLoader} />
        )}
      </SkipRenderOnServer>
    </div>
  );
});

export default ProductTile;
