'use client';

import type { FC } from 'react';
import type { IImage } from '@/services/models/Media/Image';
import { ISliderProps, Slide, Slider } from '../../../Slider';
import { Image } from '../../../core-ui/Image';

import S from './styles.base.module.scss';

export interface IQuickviewCarouselProps {
  /** Images to display in the carousel. */
  images: Array<IImage>;
}

/** The main carousel for the PDP. */
export const QuickviewCarousel: FC<IQuickviewCarouselProps> = ({ images }) => {
  const sliderConfig = {
    height: '100%',
    loop: true,
    navigation: {
      enabled: true,
      buttonClassName: S.navigation,
      next: { className: S.next },
      prev: { className: S.prev }
    },
    pagination: {
      inset: false,
      activeClassName: S.activeCircle,
      wrapperClassName: S.pagination,
      buttonClassName: S.circle
    }
  } as ISliderProps;

  return (
    <div className={S.container}>
      <Slider {...sliderConfig}>
        {images.map(({ src, alt, uuid }) => (
          <Slide key={src}>
            <Image image={{ src, alt, uuid }} className={S.imageContainer} />
          </Slide>
        ))}
      </Slider>
    </div>
  );
};
