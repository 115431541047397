'use client';

import { FunctionComponent, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Breakpoint,
  Breakpoints
} from '@/react/components/core-ui/Breakpoints';

import { useRecommendedProducts } from '@/react/hooks/useRecommendedProducts';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import type { RecommendationContext } from '@/configs/recommendations';
import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import ProductTile from '../../ProductTile';
import { ISliderProps, Slide, Slider } from '../../../Slider';
import S from './styles.base.module.scss';
import { product_recommendations_title } from "@/lang/__generated__/ahnu/product_recommendations_title";

export interface IRecommendationsCarouselProps {
  /**
   * The recommendation context to render.
   */
  context: RecommendationContext;
}

/**
 * A slider intended to show product tiles on the PDP.
 */
const DEFAULT_VARIANT = 'recommendation';

/**
 * Product recommendations carousel.
 */
export const RecommendationsCarousel: FunctionComponent<
  IRecommendationsCarouselProps
> = ({ context }) => {
  const [msg] = useLocaleMessage();
  const { products, title, isRecommendationEnabled, isSlideOnMobile } =
    useRecommendedProducts(context);

  const containerRef = useRef<HTMLDivElement>(null);
  const key = uuidv4();
  // Get the recommendations config.
  const recommendationsConfig =
    ConfigurationService.getConfig('recommendations');
  // Find the number of slides per view for desktop vs mobile.
  const slidesPerView = recommendationsConfig.getSetting(
    'pdp.slider.slidesPerView'
  ).value;
  const mobileSlidesPerView = recommendationsConfig.getSetting(
    'pdp.slider.mobileSlidesPerView'
  ).value;

  const sliderProps = {
    direction: 'horizontal',
    loop: true,
    centeredSlides: false,
    initialSlide: 0,
    navigation: {
      inset: true,
      arrowVariant: 'arrow',
      buttonClassName: S.navigation
    },
    pagination: { enabled: false },
    height: 'auto',
    spaceBetween: 8
  } as ISliderProps;

  /**
   * Generates the slides for the slider.
   * @returns Slides - array of slides to be rendered in the slider.
   */
  const slides = useMemo(() => {
    if (products.length) {
      return products?.map((product, index) => {
        const key = `${product.sku}_${index}`;
        return (
          <Slide key={key}>
            <div className={S.slide}>
              <ProductTile
                product={product}
                variant={DEFAULT_VARIANT}
                showRatingStars={false}
              />
            </div>
          </Slide>
        );
      });
    }
    return null;
  }, [products]);

  if (!isRecommendationEnabled) {
    return null;
  }

  return (
    <div ref={containerRef} className={S.container}>
      <div className={S.title}>
        {title ?? msg(product_recommendations_title)}
      </div>
      <Breakpoints>
        <Breakpoint media="md:xl">
          <Slider
            key={`${key}_slider`}
            {...sliderProps}
            slidesPerView={slidesPerView ?? 1}
            sliderClassName={S.slider}
            navigationWrapperClassName={S.wrapper}
          >
            {slides}
          </Slider>
        </Breakpoint>
        <Breakpoint default>
          <Slider
            key={`${key}_slider_mobile`}
            {...sliderProps}
            slidesPerView={mobileSlidesPerView ?? slidesPerView ?? 1}
            sliderClassName={S.slider}
            navigationWrapperClassName={S.wrapper}
          >
            {slides}
          </Slider>
        </Breakpoint>
      </Breakpoints>
    </div>
  );
};
