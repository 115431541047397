'use client';

import { FunctionComponent, useMemo } from 'react';

// Import libraries
import { classes } from '@/next-utils/css-utils/scss-utils';
import { useRecommendedProducts } from '@/react/hooks/useRecommendedProducts';
import { RecommendationContext } from '@/configs/recommendations';

import { useProductVM } from '@/react/hooks/useProductVM';
import { isNullish } from '@/utils/null-utils';
import ProductTileBuy from '@/react/components/product-list/ProductTileBuy';

// Import styles and assets
import S from './styles.module.scss';

export interface IRecommendationsProps {
  /**
   * The recommendation context to render.
   */
  context: RecommendationContext;
}

/** Component that displays a single tile buy product recommendation.  */
const PairsWith: FunctionComponent<IRecommendationsProps> = ({ context }) => {
  const { products, title, isRecommendationEnabled } = useRecommendedProducts(
    context,
    1
  );

  const product = useMemo(() => products?.[0], [products]);
  const productVM = useProductVM(product);

  return (
    <>
      {isRecommendationEnabled && !isNullish(productVM) && (
        <div className={classes(S.container)}>
          <h3 className={S.title}>{title}</h3>
          <ProductTileBuy productVM={productVM} />
        </div>
      )}
    </>
  );
};

export default PairsWith;
