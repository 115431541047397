'use client';

import GenericSelector from '@/react/components/product/VariationSelector/GenericSelector';
import type { IShoeHeightSelectorProps } from '@/react/components/product/VariationSelector/ShoeHeightSelector';
import { DisplayVariationAttributeType } from '@/react/view-models/ProductVM';
import { observer } from 'mobx-react-lite';

import { SHOE_HEIGHT_ORDER, type ShoeHeight } from './ShoeHeightMetadata';

import S from './styles.module.scss';
/**
 * Shows a buttons component with the shoe height options.
 */
export const ButtonShoeHeightSelector = observer(
  function ButtonShoeHeightSelector({ product }: IShoeHeightSelectorProps) {
    return (
      <GenericSelector
        style={{ minWidth: '100px' }}
        product={product}
        variationType={DisplayVariationAttributeType.ShoeHeight}
        sortFn={(a: string, b: string) =>
          SHOE_HEIGHT_ORDER.indexOf(a as ShoeHeight) -
          SHOE_HEIGHT_ORDER.indexOf(b as ShoeHeight)
        }
        // Since product group names are unpredictable, there may not be a 1:1; for now, this function should be consistent
        // how the value string is received.
        valueFormatterFn={(value: string) =>
          value.split('-').at(0)?.toLocaleUpperCase() ?? value
        }
        className={S.smallButton}
      />
    );
  }
);
