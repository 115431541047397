'use client';

import type { CSSProperties, FC } from 'react';

import { setupPreviews } from '@previewjs/plugin-react/setup';
import { isNullish, isNullOrEmpty } from '@/utils/null-utils';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { Icon } from '../Icon';
import { getRatingAsStars } from './getRatingAsStars';

import S from './styles.base.module.scss';

export interface IRatingStarsProps {
  /** The rating score (a real number between 1 and 5) to display. */
  rating: number;

  /** Number of ratings for a given product. */
  count?: number;

  /** Custom star size in pixels. Will be applied to both height and width. Takes priority over all styles. */
  starSize?: number;

  /** Custom star color. Takes priority over all styles. */
  starColor?: string;

  /** CSS class name(s) for the container. */
  containerClassName?: string;

  /** CSS ID for the container. */
  containerId?: string;

  /** Inline styles for the container.  */
  containerStyle?: { [key: string]: CSSProperties };

  /** CSS class name(s) for the stars. */
  starClassName?: string;

  /** Inline styles for the stars.  */
  starStyle?: { [key: string]: CSSProperties };
}

/**
 * Displays the stars for a given rating score.
 */
const RatingStars: FC<IRatingStarsProps> = ({
  rating,
  count,
  containerStyle,
  starSize,
  starColor,
  containerClassName,
  containerId,
  starClassName,
  starStyle
}) => {
  const starIcons = getRatingAsStars(rating);

  return (
    <div
      className={classes(S.ratingStars, containerClassName)}
      id={containerId}
      style={{
        ...containerStyle,
        // Apply height if `starSize` is present.
        ...(!isNullish(starSize) && { height: `${starSize}px` })
      }}
    >
      {starIcons.map((Star) => (
        // eslint-disable-next-line react/jsx-key -- Icons don't have state.
        <Icon
          icon={Star}
          className={classes(S.star, starClassName)}
          style={{
            ...starStyle,

            // Apply width and height if `starSize` is present.
            ...(!isNullish(starSize) && {
              width: `${starSize}px`,
              height: `${starSize}px`
            }),

            // Apply custom color only if `starColor` is present.
            ...(!isNullOrEmpty(starColor) && { color: starColor })
          }}
        />
      ))}
      {
        // Ratings number.
        !isNullish(count) && <span className={S.count}>{`(${count})`}</span>
      }
    </div>
  );
};

export default RatingStars;

/** @see https://previewjs.com/docs/features/custom-previews */
setupPreviews(RatingStars, () => ({
  default: { rating: 1 } as IRatingStarsProps
}));
