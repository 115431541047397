'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { msg } from '@/services/isomorphic/I18NService';
import { FC, MouseEventHandler, useCallback } from 'react';
import { IconSizeProp } from '@/react/components/core-ui/Icon';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import {
  useSliderController,
  useSliderOptions,
  useSliderState
} from '../../../utils/hooks';
import { deriveButtonDirection } from '../helpers';
import { SliderNavigationButtonIcon } from '../SliderNavigationButtonIcon';
import type { ISliderNavigationButtonProps } from './ISliderNavigationButtonProps';

import S from './styles.base.module.scss';
import { general_nextSlide } from "@/lang/__generated__/ahnu/general_nextSlide";
import { general_previousSlide } from "@/lang/__generated__/ahnu/general_previousSlide";

/**
 * The default button component that renders the navigation button.
 * Based on the navigateTo prop, renders a button that either navigates to the
 * next or the previous slide.
 * Based on the direction of the slider (vertical | horizontal)
 * and the direction of navigation (next | previous),
 * this component derives the icon direction (up | down | left | right)
 * and renders the icon component that can be a custom or the default
 * SliderNavigationButtonIcon component.
 *
 * This button is default, meaning in the future there might be an option
 * which would allow a different FunctionComponent to be used instead of this button.
 * However it's highly unlikely, since the functionality this button implements
 * isn't something that would change much. The icon (arrow) can be changed by providing a custom
 * SliderNavigationButtonIcon if there's need for further customization outside of
 * the existing arrowVariants.
 *
 * @example ```tsx
 * <SliderNavigationButton navigateTo="next" />
 * <SliderNavigationButton navigateTo="previous" />
 * ```
 */
export const SliderNavigationButton: FC<ISliderNavigationButtonProps> = ({
  navigateTo
}) => {
  const sliderState = useSliderState();
  const sliderController = useSliderController();
  const options = useSliderOptions().navigation;

  const { inset, arrowVariant, buttonClassName } = options;

  const isNext = navigateTo === 'next';

  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (isNext) {
        sliderController.next();
      } else {
        sliderController.previous();
      }
    },
    [isNext, sliderController]
  );

  /**
   * If this button is for the next slide, but there is no next slide
   * or if this is a previous button and there is no previous slide.
   */
  const isDisabled =
    (isNext && !sliderState.hasNext) || (!isNext && !sliderState.hasPrevious);
  const direction = deriveButtonDirection(sliderState.direction, isNext);

  const Icon = options.buttonIconComponent ?? SliderNavigationButtonIcon;

  // Check to see what size the brand wants the slider icon to be.
  const sliderIconSize = useBrandLocaleValue<IconSizeProp>(
    () => ({
      default: IconSizeProp.Size1X
    }),
    []
  );

  return (
    <button
      type="button"
      className={classes(S.button, inset && S.inset, buttonClassName)}
      disabled={isDisabled}
      onClick={onClick}
      data-direction={direction}
      aria-label={
        isNext ? msg(general_nextSlide) : msg(general_previousSlide)
      }
    >
      {arrowVariant && (
        <Icon
          direction={direction}
          variant={arrowVariant}
          size={sliderIconSize}
        />
      )}
    </button>
  );
};
