import { mocked } from '@/configs';

import { Constructor, DTO } from '@/type-utils';
import type { RecommendationContext } from '@/configs/recommendations';
import Service from '../../Service';

import MockService, { MockState, ServiceMock } from '../MockService';
import type { RecommendationService } from './RecommendationService';
import { Brand, EnvironmentService } from '../EnvironmentService';
import type IRecommendationsData from './IRecommendationsData';
import { getContextConfig } from './getContextConfig';

const AHNU_MOCK_RECOMMENDATION = {
  title: 'New Arrivals',
  productList: ['1166670-WWH', '1166670-WWH', '1166670-WWH', '1166670-WWH']
};

const TEVA_MOCK_RECOMMENDATION = {
  title: 'You Might Also Like',
  productList: ['1090969-BBKL', '1090969-BASL', '1090969-CYM', '1090969-MCBM']
};

/**
 * Gets the mock recommendation data for the current brand.
 * @returns Mock recommendations data.
 */
export const MOCK_RECOMMENDATION = (): IRecommendationsData => {
  const { brand } = EnvironmentService;

  switch (brand) {
    case Brand.Ahnu: {
      return AHNU_MOCK_RECOMMENDATION;
    }
    case Brand.Teva: {
      return TEVA_MOCK_RECOMMENDATION;
    }
    default: {
      return AHNU_MOCK_RECOMMENDATION;
    }
  }
};

const initialState = {};

/**
 * Mock implementation of the RecommendationService class.
 */
class RecommendationServiceMock extends ServiceMock<RecommendationService> {
  protected _state;

  /** @inheritdoc */
  public get state(): MockState {
    return this._state;
  }

  /** @inheritdoc */
  public getMock(): RecommendationService {
    return MockService.getMockOf(
      this.service
    ) as unknown as RecommendationService;
  }

  /**
   * The constructor to initialize the mocks.
   * @param service - The service that is being mocked.
   */
  public constructor(private service: Constructor<RecommendationService>) {
    super();
    this._state = new MockState(initialState);
    this.initializeMockedMembers(service);
  }

  /** @inheritdoc */
  protected initializeMockedMembers(
    service: Constructor<RecommendationService>
  ): void {
    const mockEnabled: boolean = mocked.RecommendationService;

    MockService.mockService(
      mockEnabled,
      service,
      {
        getRecommendations: async () => {
          return Promise.resolve(MOCK_RECOMMENDATION());
        },
        getContextConfig: (context: RecommendationContext) => {
          const { isRecommendationEnabled, isSlideOnMobile } =
            getContextConfig(context);
          return { isRecommendationEnabled, isSlideOnMobile };
        }
      },
      {},
      this.state
    );
  }
}

export default RecommendationServiceMock;
