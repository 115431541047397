'use client';

import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { Button } from '@/react/components/core-ui/Button';
import { Icon, IconSizeProp, IconTypes } from '@/react/components/core-ui/Icon';
import type { IProductVM } from '@/react/view-models/ProductVM';
import { EventType } from '@/services/isomorphic/UserInteractionService';

import { Modal } from '@/react/components/core-ui/Modal';
import { ModalHeader } from '@/react/components/core-ui/Modal/ModalHeader';
import { classes } from '@/next-utils/css-utils/scss-utils';
import { DisplayVariationAttributeType } from '@/react/view-models/ProductVM';
import { SizeChart } from '@/react/components/product/SizeChart';
import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import { maybe } from '@/utils/null-utils';
import { ProductAvailabilityState } from '@/services/models/Inventory';

import ConfigurationService from '@/services/isomorphic/ConfigurationService';
import { SizeButton } from './SizeButton';
import { SizeDropdown } from './SizeDropdown';

import S from './styles.base.module.scss';
import { product_details_sizeSelectedMsg } from "@/lang/__generated__/ahnu/product_details_sizeSelectedMsg";
import { product_details_sizeChart } from "@/lang/__generated__/ahnu/product_details_sizeChart";

/** The different variants. */
export enum SizeSelectorVariant {
  /** These display the sizes in a row. They can wrap to other lines but are not in a grid. */
  Default = 'default',
  /** These display the sizes in a dropdown. */
  Dropdown = 'dropdown'
}

export interface ISizeSelectorProps {
  /** The product to display size options for. */
  product: IProductVM;
  /** Whether the size chart should be displayed. */
  showSizeChart?: boolean;
  /** What type of size chart gets displayed. */
  variant?: SizeSelectorVariant;
}

/** Size selector component for the pdp. */
export const SizeSelector = observer(function SizeSelector({
  product,
  showSizeChart = true,
  variant = SizeSelectorVariant.Default
}: ISizeSelectorProps) {
  const [msg] = useLocaleMessage();
  const sizes = product.getVariationAttributesByType(
    DisplayVariationAttributeType.Size
  );

  // Get the selected size.
  const selectedSize = maybe(sizes.filter((size) => size.isSelected)[0]);

  const [sizeChartOpen, setSizeChartOpen] = useState(false);

  const handleClose = useCallback(() => {
    setSizeChartOpen(false);
  }, []);

  // Get the availability status of the product.
  const { status, text: availabilityText } = product.availability;

  // Check to see if the product is Out of Stock or Unavailable.
  const isOutOfStockOrUnavailable =
    status === ProductAvailabilityState.OutOfStock ||
    status === ProductAvailabilityState.Unavailable;

  // Check if the size chart link should be displayed.
  const productConfig = ConfigurationService.getConfig('product');
  const showSizeChartLink = showSizeChart
    ? productConfig.getSetting('sizeSelector.showSizeChartLink').value
    : false;

  if (sizes.length <= 1 && product.isAccessory) {
    return null;
  }

  return (
    <div
      className={
        variant === SizeSelectorVariant.Default
          ? S.container
          : S.dropdownContainer
      }
    >
      <div className={S.sizeSelectorTop}>
        {variant === SizeSelectorVariant.Default && (
          <>
            <div className={S.sizeSelectMsg}>
              {msg(product_details_sizeSelectedMsg)}
              <span className={S.value}>
                {selectedSize ? selectedSize.metadata.name : ''}
              </span>
            </div>
            <div
              className={classes(S.statusMessage, {
                [S.warning]: isOutOfStockOrUnavailable,
                // UX requirement: If the product is in stock, do not show the availability text.
                [S.visible]: status !== ProductAvailabilityState.InStock
              })}
            >
              {availabilityText}
            </div>
          </>
        )}
      </div>
      <div className={S.sizes}>
        {variant === SizeSelectorVariant.Default ? (
          sizes.map((size) => (
            <SizeButton key={size.value} size={size} product={product} />
          ))
        ) : (
          <SizeDropdown product={product} />
        )}
      </div>
      {/* Size Chart Link */}
      {showSizeChartLink && (
        <div className={S.sizeRow}>
          <Button
            className={S.sizeChart}
            variant="link"
            onClick={() => setSizeChartOpen(true)}
            interactionDetails={{
              action: EventType.ProductSizeChart
            }}
          >
            <span className={S.ruler}>
              <Icon icon={IconTypes.Ruler} size={IconSizeProp.SizeLG} />
            </span>
            <span>
              {product.sizeChart?.text ?? msg(product_details_sizeChart)}
            </span>
          </Button>
          <div className={S.sizeCallout}>{product.sizeChart?.callout}</div>
        </div>
      )}

      {/* Size Chart Modal */}
      <Modal className={S.modal} isOpen={sizeChartOpen} onClose={handleClose}>
        <ModalHeader className={S.modalHeader} onClose={handleClose}>
          <h3>{msg(product_details_sizeChart)}</h3>
        </ModalHeader>
        <div className={S.modalBody}>
          <SizeChart />
        </div>
      </Modal>
    </div>
  );
});
