'use client';

// Import libraries
import { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';

import { Image } from '@/react/components/core-ui/Image';
import { classes } from '@/next-utils/css-utils/scss-utils';

// Import components
import { PriceModel } from '@/services/models/Price';
import { EventType } from '@/services/isomorphic/UserInteractionService';
import {
  DisplayVariationAttributeType,
  type IProductVM
} from '@/react/view-models/ProductVM';
import { Link } from '@/react/components/core-ui/Link';
import { Price } from '@/react/components/product/Price';
import {
  SizeSelector,
  SizeSelectorVariant
} from '@/react/components/product/VariationSelector/SizeSelector';
import AddToCartButton from '@/react/components/cart/AddToCartButton';
import type { Nullable } from '@/type-utils';
import { VariationAttributeType } from '@/services/models/Product/variation-attributes';
import ITestIdentifiable from '../../traits/ITestIdentifiable';
import type { ISwatchVariation } from '../ProductTile/ProductSwatchSlider/Swatch';
import { ProductSwatchSlider } from '../ProductTile/ProductSwatchSlider';
import ProductDetailsVariant from '../../product/ProductDetailsVariant';

import S from './styles.base.module.scss';

/** Defines the possible badge types a product tile can have. */
export enum ProductBadge {
  newStyle = 'newStyle'
}

/** Defines props for the props `ProductTile` component. */
export interface IProductTileBuyProps extends ITestIdentifiable {
  /** The product to display, this should use the actual ProductVM since this is purchasable. */
  productVM: IProductVM;
}

/** Component that displays a product in a tile that can be used to add to cart.  */
const ProductTileBuy: FunctionComponent<IProductTileBuyProps> = observer(
  ({ productVM, datatestID }) => {
    const { name, primaryImage, price } = productVM;
    const priceModel = price ? PriceModel.from(price) : null;

    // Retrieve paths to send to product page from a category page.
    const href = productVM.urlWithCategory;

    const colorVariants = productVM.getAvailableAttributesByType(
      VariationAttributeType.Color
    );

    // Create swatches for each color variant.
    const swatches =
      colorVariants?.map((color) => {
        const [sku] = productVM.selectAttributeSKU(color);

        return {
          sku,
          ...color
        };
      }) ?? [];

    const onSwatchChange = async ([swatch]: [
      ISwatchVariation,
      Nullable<ISwatchVariation>
    ]): Promise<void> => {
      try {
        await productVM.selectVariationAttribute(
          DisplayVariationAttributeType.Color,
          swatch.value
        );
      } catch (error) {
        await productVM.unselectVariationAttribute(
          DisplayVariationAttributeType.Size
        );

        await productVM.selectVariationAttribute(
          DisplayVariationAttributeType.Color,
          swatch.value
        );
      }
    };

    return (
      <div className={classes(S.tile)} data-testid={datatestID}>
        <div className={S.content}>
          <div className={S.imageContainer}>
            <div className={S.imageWrapper}>
              <Link
                href={href}
                variant="text"
                interactionDetails={{
                  action: EventType.TileClick,
                  product: productVM.currentProduct
                }}
                className={S.imageLink}
              >
                <Image image={primaryImage} />
              </Link>
            </div>
          </div>

          <div className={S.panel}>
            <Link
              href={href.toString()}
              variant="text"
              interactionDetails={{
                action: EventType.TileClick,
                product: productVM.currentProduct
              }}
              datatestID="plpProductName"
            >
              <div className={S.productName}>{name}</div>
              <Price className={S.price} price={priceModel} />
            </Link>
            <div className={S.colors}>
              <ProductSwatchSlider
                swatches={swatches}
                swatchesPerSlide={3}
                onChange={onSwatchChange}
                activeSwatch={swatches.find((swatch) => {
                  return swatch.value === productVM.selectedVariations.color;
                })}
              />
            </div>
            <div className={S.sizeSelector}>
              <SizeSelector
                product={productVM}
                showSizeChart={false}
                variant={SizeSelectorVariant.Dropdown}
              />
            </div>
            <AddToCartButton
              product={productVM}
              buttonVariant="transparent-dark"
              variant={ProductDetailsVariant.Tile}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default ProductTileBuy;
