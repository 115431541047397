import { IconTypes } from '../Icon';

/**
 * Converts a rating score to an array of star icons.
 * @param rating - The rating score (a real number between 1 and 5).
 * @returns An array of star icons representing the rating score.
 */
export function getRatingAsStars(rating: number): Array<IconTypes> {
  const stars: Array<IconTypes> = [];
  const fullStars = Math.floor(rating);
  const halfStar = rating - fullStars >= 0.5;
  for (let i = 0; i < 5; i++) {
    if (i < fullStars) stars.push(IconTypes.FullStar);
    else if (i < fullStars + (halfStar ? 1 : 0)) stars.push(IconTypes.HalfStar);
    else stars.push(IconTypes.EmptyStar);
  }

  return stars;
}
